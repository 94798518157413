import * as React from 'react';
import * as Rebass from 'rebass';
import styled from '@emotion/styled';
import { withStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import * as Color from 'color';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ReactImage from 'react-image';
import { path } from 'ramda';
import { Link as GatsbyLink } from 'gatsby';
import Hidden from '@material-ui/core/Hidden';

import { useStaticQuery, graphql, navigate } from 'gatsby';

const DarkenColor = Color('rgb(60, 137, 239)').darken(0.1).hsl().toString();

// const BlueButton = withStyles((theme: Theme) => ({
//   root: {
//     color: theme.palette.getContrastText('rgb(60, 137, 239)'),
//     // boxShadow: 'none',
//     paddingLeft: theme.spacing(5),
//     paddingRight: theme.spacing(5),
//     paddingTop: theme.spacing(2),
//     paddingBottom: theme.spacing(2),
//     textTransform: 'none',
//     fontSize: 16,
//     padding: '6px 12px',
//     border: '1px solid',
//     lineHeight: 1.5,
//     backgroundColor: 'rgba(60, 137, 239)',
//     borderColor: 'rgba(60, 137, 239)',
//     boxShadow: '0px 3px 20px rgba(60, 137, 239, 0.5)',
//     width: '100%',
//     // fontFamily: [
//     //   'Nunito',
//     //   // 'Avenir'
//     // ].join(','),
//     fontWeight: 600,
//     '&:hover': {
//       backgroundColor: DarkenColor,
//       borderColor: DarkenColor,
//     },
//     '&:active': {
//       boxShadow: 'none',
//       backgroundColor: DarkenColor,
//       borderColor: DarkenColor
//     },
//     '&:focus': {
//       // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
//     },
//     '&:visited, &:link': {
//       color: '#fff'
//     }
//   },
//   text: {
//     color: "#fff"
//   },
//   label: {
//     whiteSpace: 'nowrap'
//   }
// }))(Button);

const BlueButton = withStyles((theme: Theme) => ({
  root: {
    // color: theme.palette.getContrastText('rgb(60, 137, 239)'),
    color: '#3c8aef !important',
    // boxShadow: 'none',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'rgba(237,246,255, 0.5)',
    borderColor: 'rgba(60, 137, 239)',
    // boxShadow: '0px 3px 20px rgba(60, 137, 239, 0.5)',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    // fontFamily: [
    //   'Nunito',
    //   // 'Avenir'
    // ].join(','),
    // fontWeight: 600,
    '&:hover': {
      backgroundColor: 'rgba(72,177,251, 0.2)',
      borderColor: DarkenColor
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(237,246,255)',
      borderColor: DarkenColor
    },
    '&:focus': {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  },
  text: {
    color: '#3c8aef'
  }
}))(Button)

const PrimaryText = styled(Rebass.Text)`
  font-family: Avenir;
  font-weight: 300;
  color: #071D3B;
  letter-spacing: 0;
`

PrimaryText.defaultProps = {
  fontSize: [5]
}

const SecondaryText = styled(Rebass.Text)`
opacity: 0.5;
font-family: 'Open Sans';
font-weight: 300;
color: #071D3B;
letter-spacing: 0;
line-height: 1.5;
`

SecondaryText.defaultProps = {
  fontSize: [3],
  lineHeight: 1,
  mt: 2
}

const IconWrapper = styled(Rebass.Box)`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
  /* box-shadow: 0 4px 15px 0 rgba(7,29,59,0.06); */
`

IconWrapper.defaultProps = {
  mr: [4],
  mb: [4, 0]
}

const LeftWrapper = styled(Rebass.Flex)``

LeftWrapper.defaultProps = {
  flexDirection: 'row',
  width: [1, 3 / 4],
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: ['center', 'flex-start']
}

const RightWrapper = styled(Rebass.Flex)``

RightWrapper.defaultProps = {
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: [1, 1 / 4],
  mt: [3, 0],
  mx: [3, 0]
}

export const CTAMakeAnAppointment = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          tollFree
        }
      }
      callIcon: file(
        relativePath: {
          eq: "icons/call-to-action.svg"
        },
        sourceInstanceName: {
          eq: "assets"
        }
      ) {
        publicURL
      }
      allContentfulLayoutCopy(
          filter: { node_locale: { eq: "en" }, title: { eq: "Landing > Make an Appointment" } }
          limit: 1
        ) {
          edges {
            node {
              headline
              ctaTitle
              ctaLink
              copy {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
            }
          }
        }
    }
  `);
  // const { tollFree } = data.site.siteMetadata;
  const headline = path(['allContentfulLayoutCopy', 'edges', 0, 'node', 'headline'], data);
  const copy = path(['allContentfulLayoutCopy', 'edges', 0, 'node', 'copy', 'childMarkdownRemark', 'rawMarkdownBody'], data);
  const ctaLink = path(['allContentfulLayoutCopy', 'edges', 0, 'node', 'ctaLink'], data);
  const ctaTitle = path(['allContentfulLayoutCopy', 'edges', 0, 'node', 'ctaTitle'], data);
  return (
    <section>
      <Container maxWidth="lg">
        <Box my={4}>
          <Grid container={true} spacing={3}>
            <Hidden implementation="js" mdDown>
              <Grid item={true} md={1}>
                <ReactImage src={data.callIcon.publicURL} />
              </Grid>
            </Hidden>
            <Grid item={true} xs={12} md={8}>
              <PrimaryText>{headline}</PrimaryText>
              <SecondaryText>{copy}</SecondaryText>
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <GatsbyLink
                to="/en/resources/forms/request-appointment"
                style={{ textDecoration: 'none' }}
              >
                <BlueButton>
                  {ctaTitle}
                </BlueButton>
              </GatsbyLink>
            </Grid>
          </Grid>
        </Box>

      </Container>
    </section>
  );
} 