import * as React from 'react';
import * as Rebass from 'rebass';
import styled from 'styled-components';

const HeaderText = styled(Rebass.Text).attrs({
  fontFamily: 'Avenir',
  fontWeight: 300,
  fontSize: [5],
  mt: 4
})``

export const SectionHeader = ({ title, ...other }: { title: string }) => (
  <HeaderText {...other}>{title}</HeaderText>
);