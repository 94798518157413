import * as React from 'react'
import * as Rebass from 'rebass'
import styled from '@emotion/styled'
import Measure from 'react-measure'
import { HeroContext } from '../../contexts/hero-context'
import { space, color } from 'styled-system'
import {Link as GatsbyLink, graphql, Link, navigate, useStaticQuery } from 'gatsby'
import { withStyles, Theme, createStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import * as Color from 'color'
import { path } from 'ramda'

const DarkenColor = Color('rgb(60, 137, 239)')
  .darken(0.1)
  .hsl()
  .toString()

// const theme = require('../../common/theme')
const HeroBGLarge = require('../../assets/hero-bg-large.svg')

const introText = `With two locations in the core of downtown Toronto to serve you, maintaining your oral health has never been more convenenient.  At Ari Dental we combine passion our  with years of experience to deliver a comprehensive service that will exceed your expectations.  We welcome you to join our practice and experience the diference. Your excellent oral health is our mission.`

const Smooch = (global as any).Smooch

const DesktopWrapper = styled(Rebass.Flex)`
  flex-direction: row;
  justify-content: flex-end;
  background-color: rgba(60, 138, 239, 0.07);
  width: 100%;
  border-top: 3px solid #fff;
`

const BackgroundWrapper = styled(Rebass.Flex)`
  flex-direction: column;
  width: 100%;
  min-width: 52em;
`

const HoistContainer = styled(Rebass.Flex)`
  position: absolute;
  width: 100%;
  top: 64px;
  left: 0;
  @media(min-width: 600px) {
    left: 2em;
  }
`

const BaseColumn = styled(Rebass.Flex)`
  max-width: 100%;
`

BaseColumn.defaultProps = {
  my: [1, 4],
  flexDirection: 'column'
}

// const BaseColumn = styled(Rebass.Flex).attrs({
//   my: [1, 4],
//   flexDirection: 'column'
// })`
//   max-width: 100%;
// `

const ContentColumn = styled(BaseColumn)`
  align-items: flex-start;
  flex-direction: column;
`

ContentColumn.defaultProps = {
  my: [1, 4],
  width: [1, 7 / 12],
  px: [3, 0]
}

// const ContentColumn = styled(BaseColumn).attrs({

// })`
//   align-items: flex-start;
// `

// const IllustrationColumn = styled(BaseColumn).attrs({
//   // mt: [5, 0]
//   px: [3, 0]
// })`
//   flex-basis: 20em;
//   flex-grow: 9999;
//   @media only screen and (max-width : ${theme.breakpoints[1]}) {
//     margin-top: 50px;
//   }
// `;

// const Header = styled(Rebass.Text).attrs({
//   as: 'h1',
//   fontFamily: 'Avenir',
//   fontSize: [5, '38px'],
//   fontWeight: 300,
//   lineHeight: 1.25,
//   mt: [4, 3]
// })`
//   ${space}
// `

// const Header = styled(Rebass.Text)`
//   ${space}
// `

// Header.defaultProps = {
//   as: 'h1',
//   fontFamily: 'Avenir',
//   // fontSize: [5, '38px'],
//   fontSize: [5],
//   fontWeight: 300,
//   lineHeight: 1.25,
//   mt: [4, 3]
// }

const Header = withStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontFamily: 'Avenir'
  }
}))(Typography)

// const IntroText = ({ ...props }) =>
//   <Rebass.Text fontFamily='Open Sans' fontSize={[2, '16px']} fontWeight={300} lineHeight={[1.7]}
//   mt={[4]}
//   mr={[0, 3]}
//   color={'#071D3B'}
//   {...props}
// />

const IntroText = withStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2)
  }
}))(Typography)

// const NewPatientButton = styled(Rebass.Button)`
//   border-color: #3c8aef;
//   background-color: #3c8aef;
//   border-width: 1px;
//   padding: 10px;
//   align-items: center;
//   justify-content: center;
//   border-radius: 5px;
//   box-shadow: 0px 3px 20px rgba(60, 137, 239, 0.5);
//   flex-basis: auto;
//   min-width: 245;
//   max-width: 100%;
//   height: 65px;
//   padding-left: 40px;
//   padding-right: 40px;
//   margin-top: 30px;
//   cursor: pointer;
//   @media only screen and (max-width: 40em) {
//     width: 100%;
//   }
//   font-family: 'Open Sans';
//   font-weight: bold;
//   font-size: 16px;
//   color: #FFFFFF;
//   letter-spacing: 0;
//   text-align: center;
//   &:focus {
//     outline: 0;
//   }

// `

const BlueButton = withStyles((theme: Theme) => ({
  root: {
    // color: theme.palette.getContrastText('rgb(60, 137, 239)'),
    color: '#3c8aef !important',
    // boxShadow: 'none',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'rgba(237,246,255, 0.5)',
    borderColor: 'rgba(60, 137, 239)',
    // boxShadow: '0px 3px 20px rgba(60, 137, 239, 0.5)',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    // fontFamily: [
    //   'Nunito',
    //   // 'Avenir'
    // ].join(','),
    // fontWeight: 600,
    '&:hover': {
      backgroundColor: 'rgba(72,177,251, 0.2)',
      borderColor: DarkenColor
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(237,246,255)',
      borderColor: DarkenColor
    },
    '&:focus': {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  },
  text: {
    color: '#3c8aef'
  }
}))(Button)

// const sendMessage = ({ message }) => {
//   if (
//     typeof (global as any).smoochLoaded !== 'undefined' &&
//     (global as any).smoochLoaded === true
//   ) {
//     if (!Smooch.isOpened()) {
//       Smooch.open()
//     }
//     Smooch.sendMessage(message)
//   }
// }

const StyledHeroBG = styled(HeroBGLarge)`
  @media only screen and (min-width: 40em) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`

const InnerContainer = styled(Rebass.Flex)`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
`

// const MobileBackground = styled(BackgroundWrapper).attrs({
//   // my: 4,
//   // px: 1
// })`
//   width: 100%;

//   /* display: none; */
//   /*
//   max-width: 100%;
//   @media only screen and (max-width : ${theme.breakpoints[1]}) {
//     max-width: 100%;
//     display: flex;
//   } */
// `

const DesktopBackground = styled(BackgroundWrapper)`
  @media screen and (max-width: 40em) {
    margin-top: ${(props: any) => props.contentHeight}px;
    margin-bottom: 50px;
  }
`

export const HeroSection = () => {
  const { height, setHeight } = React.useContext(HeroContext) as any

  console.log("height:", height);

  const data = useStaticQuery(
    graphql`
      query {
        allContentfulLayoutCopy(
          filter: { node_locale: { eq: "en" }, title: { eq: "Landing > Hero" } }
          limit: 1
        ) {
          edges {
            node {
              headline
              ctaTitle
              ctaLink
              copy {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
            }
          }
        }
      }
    `
  )

  const headline = path(
    ['allContentfulLayoutCopy', 'edges', 0, 'node', 'headline'],
    data
  )
  const ctaLink = path(
    ['allContentfulLayoutCopy', 'edges', 0, 'node', 'ctaLink'],
    data
  )
  const ctaTitle = path(
    ['allContentfulLayoutCopy', 'edges', 0, 'node', 'ctaTitle'],
    data
  )
  const body = path(
    [
      'allContentfulLayoutCopy',
      'edges',
      0,
      'node',
      'copy',
      'childMarkdownRemark',
      'rawMarkdownBody',
    ],
    data
  )

  return (
    <DesktopWrapper>
      <DesktopBackground contentHeight={height}>
        <StyledHeroBG />
      </DesktopBackground>
      <Measure bounds onResize={({ bounds }) => setHeight(bounds.height)}>
        {({ measureRef }) => (
          <HoistContainer ref={measureRef}>
            <InnerContainer>
              <ContentColumn>
                <Header variant="h4">{headline}</Header>
                <IntroText variant="body1">{body}</IntroText>
                {/* <BlueButton href={ctaLink}>{ctaTitle}</BlueButton> */}
                <GatsbyLink
                      
                      to="en/resources/forms/new-patient-form/"
                      style={{ textDecoration: "none", marginTop: "2rem" }}
                    >
                      <Button 
                        
                        variant="outlined"
                        color="primary"
                        
                      >
                        Patient Registration
                      </Button>
                    </GatsbyLink>
              </ContentColumn>
            </InnerContainer>
          </HoistContainer>
        )}
      </Measure>
    </DesktopWrapper>
  )
}

// const styles = StyleSheet.create({
//   heroWrapper: {
//     backgroundColor: '#F2F7FE',
//     // backgroundColor: 'blue',
//     alignItems: 'center',
//     justifyContent: 'center',
//     flexDirection: 'row',
//     // minHeight: 600,
//     maxWidth: 1442,
//     flexWrap: 'wrap',
//     paddingVertical: 75
//   },
//   header: {
//     // ...webWeights.light,
//     fontSize: 39,
//     flex: 1,
//     fontFamily: 'Avenir'
//   },
//   body: {
//     fontSize: 16,
//     lineHeight: 27,
//     marginTop: 30,
//     fontFamily: 'Avenir',
//     fontWeight: 300
//   },
//   mobile: {
//     backgroundColor: '#F2F7FE',
//     flex: 1,
//     flexBasis: '100%',
//     padding: 20,
//     maxWidth: '100%'
//   },
//   wrapper: {
//     flex: 1,
//     alignItems: 'center',
//     width: '100%',
//     backgroundColor: '#F2F7FE',
//     padding: 20
//   },
//   illustrationColumn: {
//     marginTop: 20,
//     paddingHorizontal: 10,
//     flexBasis: '25em',
//     margin: 'auto',
//     flexGrow: 9999,
//     maxWidth: '100%'
//   },
//   actionColumn: {
//     flexBasis: '50%',
//     margin: 'auto',
//     alignItems: 'flex-start',
//     flexGrow: 1
//   },
//   button: Breakpoints.select({
//     marginTop: 30,
//     mobile: {
//       width: '100%'
//     },
//     tablet: {
//       width: '100%'
//     }
//   })
// });

// const accentBlue = '#3c89ef';

// const NewPatientButton = styled.div`
//   border-color: ${accentBlue};
//   background-color: ${accentBlue};
//   border-width: 1px;
//   padding: 10px;
//   align-items: center;
//   justify-content: center;
//   border-radius: 5px;
//   box-shadow: 0px 3px 20px rgba(60,137,239, 0.5);
//   flex-basis: auto;
//   min-width: 245;
//   max-width: 100%;
//   height: 100px;
//   @media only screen and (max-width : 500px) {
//     height: 500px;
//   }

// `

// export const HeroSection = () => (
//   <View style={styles.wrapper}>
//     <View style={styles.heroWrapper}>
//       <View style={styles.actionColumn}>
//         <Text style={styles.header}>Experienced Dental Professionals & Exemplary Care</Text>
//         <Text style={styles.body}>{introText}</Text>
//         <NewPatientButton text={'New Patient Registration'} onPress={() => sendMessage({ message: 'New Patient Registration' })} />
//       </View>
//       <View style={styles.illustrationColumn}>
//         <HeroIllustration />
//       </View>
//     </View>
//   </View>
// );

// export const HeroSection = () => (
//   <ResponsiveConsumer>
//     {(status) => {
//       console.log('***** HERO STATUS', status)
//       return (
//         <View style={styles.wrapper}>
//         <View style={styles.heroWrapper}>
//           <View style={[status.mobile && styles.mobile]}>
//             <View style={{ maxWidth: '100%', flexWrap: 'wrap' }}>
//               <Text style={[styles.heroText]}>Experienced Dental Professionals & Exemplary Care</Text>
//             </View>

//             <View style={{ paddingTop: 20 }}>
//               {/* <Text style={{ ...webWeights.thin, fontSize: 16, color: '#071D3B', lineHeight: 27 }}>With two locations in the core of downtown Toronto to serve you, maintaining your oral health has never been more convenenient.  At Ari Dental we combine passion our  with years of experience to deliver a comprehensive service that will exceed your expectations.  We welcome you to join our practice and experience the diference. Your excellent oral health is our mission.</Text> */}
//             </View>
//             <View style={[{ flexDirection: 'row', paddingTop: 30 }, status.mobile && { justifyContent: 'center' }]}>
//               <SolidButton text={'New Patient Registration'} onPress={() => sendMessage({ message: 'New Patient Registration' })} />
//             </View>
//           </View>
//           <View style={[status.mobile && styles.mobile]}>
//             <HeroIllustration />
//           </View>
//         </View>
//       </View>
//       )
//     }}
//   </ResponsiveConsumer>
// );
