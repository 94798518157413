import * as React from 'react';
import * as Rebass from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';

import { MemberCard } from './member-card';
import { SectionHeader } from '../../components/section-header';

const Wrapper = styled(Rebass.Flex)``

Wrapper.defaultProps = {
  pt: 4,
  pb: 5,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: '#F2F7FE',
  flexDirection: 'column'
}

const Container = styled(Rebass.Flex)`
  width: 100%;
  max-width: 1000px;
`

Container.defaultProps = {
  flexDirection: 'column'
}

const CardWrapper = styled(Rebass.Flex)`
  max-width: 1000px;
  overflow: hidden;
  overflow-x: auto;
  overscroll-behavior-x: contain;
`

CardWrapper.defaultProps = {
  flexDirection: 'row',
  width: 1,
  justifyContent: 'space-between',
  mt: 3,
  pb: 3,
  // px: 3,
}

export const TeamSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulPerson (limit: 3, filter: { node_locale: { eq: "en" } }, sort: { fields: [order], order: ASC}) { 
        edges {
          node {
            name
            title
            image {
              localFile {
                publicURL
              }
            }
            }
          }
        }
    }
  `);
  const people = data.allContentfulPerson.edges.map((person) => {
    if(person.node.name != null && person.node.title != null  && person.node.image.localeFile != null ){

      const { name, title, image } = person.node;
      const rul = image ? image.localFile.publicURL : '';

      return (
        <MemberCard name={name} role={title} image={image} />
      )
    }
  });
  return (
    <Wrapper>
      <SectionHeader my={4} title="Our Team" />
      <Container>
        <CardWrapper>
          {people}
        </CardWrapper>
      </Container>
    </Wrapper>
  )
}