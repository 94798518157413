import * as React from 'react'
import Modal from '@material-ui/core/Modal'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { Link as GatsbyLink } from 'gatsby'
// import contentfulOptions from '../common/contentful-rich-text-options'
import { path } from 'ramda';


import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import { Box } from '@material-ui/core'

export const Notifications = () => {
  const [open, setOpen] = React.useState(true)
  const handleClose = () => {
    setOpen(false)
  }
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  const data = useStaticQuery(graphql`
    query {
      allContentfulNotification(filter: {enabled: {eq: true}}, limit: 1) {
        edges {
          node {
            node_locale
            title
            message {
              json
            }
          }
        }
      }
    }
  `);

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p>
        <Typography variant="body1">{children}</Typography>
      </p>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <p>
        <Typography variant="h1" gutterBottom={true}>
          {children}
        </Typography>
      </p>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <p>
        <Typography variant="h2" gutterBottom={true}>
          {children}
        </Typography>
      </p>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <p>
        <Typography variant="h3" gutterBottom={true}>
          {children}
        </Typography>
      </p>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <p>
        <Typography variant="h4" gutterBottom={true}>
          {children}
        </Typography>
      </p>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <p>
        <Typography variant="h5" gutterBottom={true}>
          {children}
        </Typography>
      </p>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <p>
        <Typography variant="h6" gutterBottom={true}>
          {children}
        </Typography>
      </p>
    )
  }
}

  const title = path(['allContentfulNotification', 'edges', 0, 'node', 'title'], data);
  const message = path(['allContentfulNotification', 'edges', 0, 'node', 'message', 'json'], data);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        {documentToReactComponents(message, options)}
      </DialogContent>
      <DialogActions>
      <GatsbyLink
                      to="en/resources/forms/new-patient-form/"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                            

                        variant="outlined"
                        color="primary"
                        
                      >
                        Patient Registration
                      </Button>
                    </GatsbyLink>
        <Button onClick={handleClose} color="primary" variant="outlined">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
