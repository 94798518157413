import * as React from 'react'
import * as Rebass from 'rebass'
import styled from '@emotion/styled'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { withStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import * as Color from 'color'
import Hidden from '@material-ui/core/Hidden'
import { path } from 'ramda'
import { compareAsc, compareDesc, format } from 'date-fns'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'

import { SubHeader } from '../../components/sub-header/sub-header'
// import { Wrapper } from '../../components/wrapper'
import { SectionHeader } from '../../components/section-header'

const RightArrow = require('../../assets/right-arrow.svg')

const theme = require('../../common/theme')

// const CardWrapper = styled(Rebass.Flex)`
//   max-width: 1000px;
//   overflow: hidden;
//   overflow-x: auto;
//   overscroll-behavior-x: contain;
// `

// CardWrapper.defaultProps = {
//   flexDirection: 'row',
//   justifyContent: 'space-between',
//   width: 1,
//   my: 3,
//   // ml: -2,
//   pb: 3
// }

// const Card = styled(Rebass.Card)`
//   cursor: pointer;
//   min-width: 306px;
//   /* background-color: red; */
//   background: #ffffff;
//   border-radius: 4px;
//   /* background: #3C8AEF; */
//   border-radius: 0 0 4px 4px;
//   font-family: Avenir-Roman;
//   font-size: 22px;
//   color: #071d3b;
//   letter-spacing: 0;
//   line-height: 26px;
//   /* opacity: 0.5; */
//   font-family: Avenir-Light;
//   font-size: 16px;
//   color: #071d3b;
//   letter-spacing: 0;
//   font-family: Avenir;
//   font-weight: 300;
//   font-size: 16px;
//   color: #3c8aef;
//   letter-spacing: 0;
//   border-bottom: 5px solid rgb(60, 138, 239);
//   transition: all 0.15s ease-in-out;
//   box-shadow: 0 2px 16px rgba(7, 29, 59, 0.07);
//   /*
// border: 5px solid #FFFFFF;
// border-radius: 4px 4px 0 0; */

//   &:hover {
//     transform: scale(1.009);
//   }
// `

// Card.defaultProps = {
//   // borderRadius: '5px',
//   // boxShadow: '0 2px 16px rgba(7, 29, 59, 0.07)',
//   // width: [306],
//   p: [2],
//   mx: 3
//   // mx: 'auto'
// }

const PostCard = withStyles((theme: Theme) => ({
  root: {
    boxShadow: '0 2px 16px rgba(7, 29, 59, 0.07)',
    borderBottom: `${theme.spacing(0.75)}px solid rgb(60, 138, 239)`
  }
}))(Card)

const PostImage = withStyles((theme: Theme) => ({
  root: {
    height: theme.spacing(20)
  }
}))(CardMedia)


const PostTitle = withStyles((theme: Theme) => ({
  root: {
    fontFamily: 'Open Sans',
    fontSize: theme.typography.pxToRem(22),
    color: '#071d3b',
  }
}))(Typography)

const ActionText = withStyles((theme: Theme) => ({
  root: {
    fontFamily:  'Open Sans',
    color: 'rgb(60, 138, 239)',
    display: 'inline-block'
  }
}))(Typography)

// const PostTitle = styled(Rebass.Text)`
//   font-family: 'Open Sans';
//   font-size: 22px;
//   color: #071d3b;
//   letter-spacing: 0;
//   line-height: 26px;
// `

// PostTitle.defaultProps = {
//   my: 2
// }

const PostDate = styled(Rebass.Text)`
  opacity: 0.5;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 16px;
  color: #071d3b;
  letter-spacing: 0;
`
PostDate.defaultProps = {
  mt: 2
}

const ArrowWrapper = withStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
    width: theme.typography.pxToRem(30),
    marginLeft: theme.spacing(2)
  }
}))(Box)

const Post = ({ title, image, date, slug, locale }) => {
  return (
    <CardActionArea href={`/${locale}/news/${slug}`}>
      <PostCard elevation={0}>
        <PostImage image={image} />
        <Box ml={2}>
          <PostTitle>{title}</PostTitle>
          <PostDate>{date}</PostDate>
          <Box my={2}>
            <ActionText>See More</ActionText>
            <ArrowWrapper>
              <RightArrow />
            </ArrowWrapper>
          </Box>
        </Box>
      </PostCard>
    </CardActionArea>
  )
}

const OutlineButton = withStyles((theme: Theme) => ({
  root: {
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: '100%'
    }
  }
}))(Button)

OutlineButton.defaultProps = {
  color: 'primary',
  variant: 'outlined'
}

export const NewsSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulNewsPost(
        limit: 3
        sort: { fields: [publishDate], order: DESC }
        filter: { node_locale: { eq: "en" } }
      ) {
        edges {
          node {
            title
            description {
              childMarkdownRemark {
                html
                excerpt(pruneLength: 320)
              }
            }
            heroImage {
              localFile {
                publicURL
              }
            }
            publishDate
            slug
            node_locale
          }
        }
      }
    }
  `)

  const posts = data.allContentfulNewsPost.edges
    .sort((ela, elb) =>
      compareDesc(
        path(['node', 'publishDate'], ela),
        path(['node', 'publishDate'], elb)
      )
    )
    .map((post) => {
      const title = path(['node', 'title'], post)
      const slug = path(['node', 'slug'], post)
      const image = path(['node', 'heroImage', 'localFile', 'publicURL'], post)
      const date = format(path(['node', 'publishDate'], post), 'MMMM D, YYYY')
      const locale = path(['node', 'node_locale'], post)
      return (
        <Grid item={true} xs={12} md={4}>
          <Post
            title={title}
            date={date}
            slug={slug}
            image={image}
            locale={locale}
          />
        </Grid>
      )
    })

  return (
    <Box py={5}>
      <Container maxWidth="lg">
        <SubHeader title={'Stay Updated'} />
        <SectionHeader my={3} title="News &amp; Updates" />
        <Grid container={true} spacing={4}>
          {posts}
        </Grid>
        <Hidden xsUp={true}>
          <OutlineButton>More News</OutlineButton>
        </Hidden>
      </Container>
    </Box>
  )
}
