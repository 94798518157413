import * as React from 'react';
import * as Rebass from 'rebass';
import styled from '@emotion/styled';

const Line = require('../../assets/line.svg');

const SubHeaderText = styled(Rebass.Text)`
  color: #3C8AEF;
  font-size: 14px;
  margin-left: 15px;
  font-family: 'Open Sans';
  text-transform: uppercase;
`;

export const SubHeader = ({ title }) => (
  <Rebass.Flex flexDirection='row' alignItems='center' mb={[2]}>
    <Line />
    <SubHeaderText>{title}</SubHeaderText>
  </Rebass.Flex>
);

// const styles = StyleSheet.create({
//   container: {
//     flexDirection: 'row', 
//     alignItems: 'center', 
//     marginBottom: 30 
//   },
//   title: {
//     color: '#3C8AEF', 
//     fontSize: 14, 
//     marginLeft: 15,
//     fontFamily: 'Avenir'
//   }
// })

// export const SubHeader = ({ title, style }: { title: string, style?: any }) => ( 
//   <View style={[styles.container, style]}>
//     <Line />
//     <Text style={styles.title}>{title.toUpperCase()}</Text>
//   </View>
// );