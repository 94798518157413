import * as React from 'react';
import * as Rebass from 'rebass';
import styled from '@emotion/styled';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { space } from 'styled-system';
import ButtonBase from '@material-ui/core/ButtonBase';
import * as Scroll from 'react-scroll';
import { path } from 'ramda';
import Container from '@material-ui/core/Container';

import { SectionHeader } from '../../components/section-header';

// const ServicesContainer = styled(Rebass.Box)`
//   max-width: 1000px;
// `

// ServicesContainer.defaultProps = {
//   width: 1,
//   mx: [3, 0]
// }

const IconWrapper = styled(Rebass.Flex)`
  flex-direction: column;
  width: 50px;
  height: 50px;
`;

const ServiceGrid = styled(Rebass.Box)`
  color: #444;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 0px;
  }
`

ServiceGrid.defaultProps = {
  my: 5
}

const ServiceItem = styled(Rebass.Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  
  border-top: 1px solid #CDD2D8;
  &:first-child {
    border-top: none;
  }
  @media (min-width: 768px) {
    border-top: none;
    border-left: 1px solid #CDD2D8;
    &:nth-child(-n + 2) {
      border-top: none;
    }
    &:nth-child(odd) {
      border-left: none;
    }
  }
  @media (min-width: 992px) {
    border-top: 1px solid #CDD2D8;
    /* border-left: 1px solid green; */
    &:nth-child(-n + 2) {
      border-top: 1px solid #CDD2D8;
    }
    &:nth-child(odd) {
      border-left: 1px solid #CDD2D8;
    }
    &:nth-child(-n + 3) {
      border-top: none;
      /* border-top: 1px solid blue; */
    }
    &:first-child,
    &:nth-child(3n + 1) {
      border-left: none;
    }
  }
  /**/
  background: #FFFFFF;
  /* opacity: 0.5; */
  font-family: Avenir-Light;
  font-size: 16px;
  color: #071D3B;
  letter-spacing: 0;
  text-align: center;
  font-family: Avenir-Medium;
  font-size: 20px;
  color: #071D3B;
  letter-spacing: 0;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0 -4px 17px 0 rgba(255,255,255,1); 
  transition: all .4s ease-in-out;
  &:hover {
    /* box-shadow: 0 -4px 17px 0 rgba(7,29,59,0.05);  */
    /* transform: scale(1.009); 
    border: 1px solid #fff; */
  }
`

ServiceItem.defaultProps = {
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  pt: 4,
  pb: 4,
}

const ServiceName = styled(Rebass.Text)`
  font-size: 20px;
  color: #071D3B;
  letter-spacing: 0;
  text-align: center;
`

ServiceName.defaultProps = {
  fontFamily: 'Avenir',
  mt: 4
}

const ServiceDescription = styled(({ 
  lineHeight = 1.5,
  ...props 
}) => (
  <Rebass.Text 
    sx={{ 
      lineHeight
    }}
    {...props}
  />
))`
  font-family: 'Open Sans';
  opacity: 0.5;
  color: #071D3B;
  /* letter-spacing: 0; */
  text-align: center;
`

ServiceDescription.defaultProps = {
  fontSize: 1,
  mt: 3
}

const HR = styled(Rebass.Box)`
  background-image: linear-gradient(to right, #333 5%, rgba(7, 29, 59, 0) 5%);
  background-position: top;
  background-size: 6.5px 1px;
  background-repeat: repeat-x;
  height: 1px;
  max-width: 1000px;
`

HR.defaultProps = {
  mx: 'auto',
  mt: 5
}

const CirclesLeft = require('../../assets/circles-left-01.svg');

const Wrapper = styled(Rebass.Box)`
`

Wrapper.defaultProps = {
  width: 1,
  mt: 2,
  // py: 'auto'
}

const CirclesWrapper = styled(Rebass.Box)`
  min-width: 100px;
  max-width: 166px;
  float: left;
`

export const ServicesSection = (...args) => {
  const data = useStaticQuery(graphql`
  {
    allContentfulService (limit: 6) {
      edges {
        node {
          slug
          name
          description {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 320)
            }
          }
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
  `)
  const services = data.allContentfulService.edges.map((service) => {
    const svgPath = path(['node', 'icon', 'localFile', 'publicURL'], service);
    const serviceName = path(['node', 'name'], service);
    const serviceDescription = path(['node', 'description', 'childMarkdownRemark', 'excerpt'], service);
    const slug = path(['node', 'slug'], service);
    return (
        <ServiceItem onClick={() => navigate(`/en/services`)} style={{ cursor: 'pointer' }}>
          <IconWrapper>
            <Rebass.Image src={svgPath} />
          </IconWrapper>
          <ServiceName>
            {serviceName}
          </ServiceName>
          <ServiceDescription>
            {serviceDescription}
          </ServiceDescription>
        </ServiceItem>
    )
  })

  return (
      <Container>
        <HR />
        <Rebass.Flex justifyContent='center'>
          <SectionHeader title="Our Services" />
        </Rebass.Flex>
        <Rebass.Flex justifyContent='center'>
          <ServiceGrid>
            {services}
          </ServiceGrid>
        </Rebass.Flex>
      </Container>
  )
} 

/*

            <ServiceItem>
              <IconWrapper>
                <PreventionIcon />
              </IconWrapper>
              <ServiceName>
                Preventive Dentistry
              </ServiceName>
              <ServiceDescription>
              Preventive dentistry involves educating, treating, and practicing the maintenance of your teeth and gums. Dr. Mohajer and his team are committed to caring for your oral health with effective and affordable preventive dentistry services.
              </ServiceDescription>
            </ServiceItem>
            <ServiceItem>
              <IconWrapper>
                <DentacCare />
              </IconWrapper>
              <ServiceName>
                Orthodontics
              </ServiceName>
              <ServiceDescription>Our orthodontic services can help you achieve the healthy and beautiful smile you've always dreamed of! At Ari Dental, we proudly offer a wide range of orthodontic services, from traditional braces to Invisalign, to patients of all ages.</ServiceDescription>
            </ServiceItem>
            <ServiceItem>
              <IconWrapper>
                <WhiteningIcon />
              </IconWrapper>
              <ServiceName>Cosmetic Dentistry</ServiceName>
              <ServiceDescription>
              Get the smile you want and boost your confidence in as little as a few visits. Ari Dental offers a variety of affordable cosmetic dentistry services to help you achieve a brighter, whiter, and straighter smile! 
              </ServiceDescription>
            </ServiceItem>
            <ServiceItem>
              <IconWrapper>
                <CalculusIcon />
              </IconWrapper>
              <ServiceName>Endodontics</ServiceName>
              <ServiceDescription>
              Endodontic treatment is a safe and effective way to help restore and preserve a damaged tooth. Our team at Ari Dental is skilled in performing root canals in order to protect and preserve your teeth.
              </ServiceDescription>
            </ServiceItem>
            <ServiceItem>
              <IconWrapper>
                <CosmeticIcon />
              </IconWrapper>
              <ServiceName>Restorative Dentistry</ServiceName>
              <ServiceDescription>
              Dental restoration is a treatment used to repair a damaged or missing tooth. At Ari Dental, our goal is to address the root cause of your dental problems, so you never have to worry about recurring problems. 
              </ServiceDescription>
            </ServiceItem>

            */
  