import * as React from 'react';
import * as Rebass from 'rebass';
import styled from '@emotion/styled';
import { Wrapper } from '../../components/wrapper';
import { graphql, useStaticQuery } from 'gatsby';
import { withStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import * as Color from 'color';


const DarkenColor = Color('rgb(60, 137, 239)').darken(0.1).hsl().toString();

const HeaderText = styled(Rebass.Text)`
  font-weight: 300;
  font-family: Avenir;
  font-size: 28px;
  line-height: 48px;
`;

HeaderText.defaultProps = {
  my: 4
}

const SubHeader = styled(Rebass.Text)`
  font-family: Avenir;
  font-weight: 500;
  font-size: 14px;
  color: #3C8AEF;
  letter-spacing: 0.52px;
`

// const ChatWithUsButton = styled(Rebass.Button)`
//   border-color: #3c8aef;
//   background-color: #3c8aef;
//   border-width: 1px;
//   align-items: center;
//   justify-content: center;
//   border-radius: 5px;
//   box-shadow: 0px 3px 20px rgba(60, 137, 239, 0.5);
//   flex-basis: auto;
//   min-width: 245px;
//   max-width: 100%;
//   height: 65px;
//   cursor: pointer;
//   font-family: Avenir;
//   font-weight: 900;
//   font-size: 16px;
//   color: #FFFFFF;
//   letter-spacing: 0;
//   text-align: center;
//   white-space: nowrap;
//   &:focus {
//     outline: 0;
//   }
// `

// ChatWithUsButton.defaultProps = {
//   // variant: 'primary',
//   mt: 3,
//   // display: 'flex',
//   px: 3,
//   width: [1, 'auto'],
// }

const BlueButton = withStyles((theme: Theme) => ({
  root: {
    // color: theme.palette.getContrastText('rgb(60, 137, 239)'),
    color: '#3c8aef !important',
    // boxShadow: 'none',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'rgba(237,246,255, 0.5)',
    borderColor: 'rgba(60, 137, 239)',
    // boxShadow: '0px 3px 20px rgba(60, 137, 239, 0.5)',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    // fontFamily: [
    //   'Nunito',
    //   // 'Avenir'
    // ].join(','),
    // fontWeight: 600,
    '&:hover': {
      backgroundColor: 'rgba(72,177,251, 0.2)',
      borderColor: DarkenColor
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(237,246,255)',
      borderColor: DarkenColor
    },
    '&:focus': {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  },
  text: {
    color: '#3c8aef'
  }
}))(Button)


const Line = styled.div`
  height: 1px;
  min-width: 46px;
  max-width: 46px;
  background-color: #4d84f1;
  margin-top: 50px;
  margin-bottom: 20px;

`

const Container = styled(Rebass.Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  width: 100%;
`

Container.defaultProps = {
  mb: 5
}

const ArrowContainer = styled(Rebass.Box)`
  min-width: 16px;
  max-width: 16px;
  margin-left: 13px;
`

const ButtonWrapper = styled(Rebass.Flex)``

ButtonWrapper.defaultProps = {
  justifyContent: 'center',
  width: [1],
  px: [3, 0]
}

// const clickHandler = (e) => {
//   e.preventDefault();

// }

export const CTAChatWithUs = () => {
  const data = useStaticQuery(graphql`
    query {
      downArrow: file(
        relativePath: {
          eq: "icons/small-down-arrow.svg"
        },
        sourceInstanceName: {
          eq: "assets"
        }
      ) {
        publicURL
      } 
    }
  `)
  return (
    <React.Fragment>
      <Wrapper>
        <Container>
          <Line />
          <SubHeader>HAVE A QUESTION?</SubHeader>
          <Rebass.Flex flexDirection='row' alignItems="center">
            <HeaderText>Chat with us now</HeaderText>
            <ArrowContainer>
              <Rebass.Image src={data.downArrow.publicURL} />
            </ArrowContainer>
          </Rebass.Flex>
          <ButtonWrapper>
            <BlueButton href="mailto:info@ari.dental?subject=Dental Appointment">
              Chat With Us
            </BlueButton>
          </ButtonWrapper>
        </Container>
      </Wrapper>
    </React.Fragment>
  )
} 