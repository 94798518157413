import * as React from 'react'
import * as Rebass from 'rebass'
import styled from '@emotion/styled'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles
} from '@material-ui/core/styles'

const DURATION = 550

const styles = (theme: Theme) =>
  createStyles({
    card: {
      minWidth: 306,
      borderBottom: '5px solid #3C8AEF',
      transition: 'all .15s ease-in-out',
      marginRight: theme.spacing(2),
      '&:first-child': {
        [theme.breakpoints.down('sm')]: {
          marginLeft: theme.spacing(3)
        }
      },
      '&:last-child': {
        [theme.breakpoints.up('md')]: {
          // marginRight: 0
        }
      },
      '&:hover': {
        transform: 'scale(1.009)'
      }
    },
    media: {
      height: 140
    },
    '@keyframes enter': {
      '0%': {
        transform: 'scale(0)',
        opacity: 0.01
      },
      '100%': {
        transform: 'scale(1)',
        // opacity: 0.3,
        opacity: 0.07
      }
    },
    '@keyframes exit': {
      '0%': {
        // opacity: 1,
        opacity: 0.01
      },
      '100%': {
        opacity: 0
      }
    },
    '@keyframes pulsate': {
      '0%': {
        transform: 'scale(1)'
      },
      '50%': {
        transform: 'scale(0.92)'
      },
      '100%': {
        transform: 'scale(1)'
      }
    },
    price: {
      textDecoration: 'none'
    },
    actionArea: {
      '&:hover $focusHighlight': {
        opacity: 0
      }
    },
    focusHighlight: {},
    child: {
      opacity: 1,
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: '50%'
      // '&:hover': {
      //   backgroundColor: 'red'
      // }
      // backgroundColor: 'hsl(210, 16%, 82%)',
    },
    rippleVisible: {
      opacity: 0.01,
      transform: 'scale(1)',
      animation: `$enter ${DURATION}ms ${theme.transitions.easing.easeInOut}`
      // '&:hover': {
      //   backgroundColor: 'blue'
      // }
    }
  })

const CardImage = withStyles((theme: Theme) => ({
  root: {
    width: '136px',
    borderRadius: '50%',
    border: '2px solid #fff',
    boxShadow: '0 6px 24px 0 rgba(7,29,59,0.17)',
    marginTop: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))(CardMedia)

export const MemberCard = withStyles(styles)(
  ({
    image,
    name,
    role,
    classes
  }: WithStyles<typeof styles> & {
    image: string
    name: string
    role: string
  }) => {
    // const data = useStaticQuery(graphql`
    //   query {
    //     file(relativePath: { eq: "portrait-01.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 136, quality: 100) {
    //             ...GatsbyImageSharpFluid
    //           }
    //         }
    //       }

    //   }
    // `);

    const { card: cardClass, ...rippleClasses } = classes

    return (
      <Card elevation={0} className={classes.card}>
        <CardActionArea
          classes={{
            root: classes.actionArea,
            focusHighlight: classes.focusHighlight
          }}
          TouchRippleProps={{ classes: rippleClasses }}
          href="/en/our-team"
        >
          <CardImage
            className={classes.media}
            image={image}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2" align="center">
              {name}
            </Typography>
            <Typography
              gutterBottom
              variant="h6"
              color="textSecondary"
              component="p"
              align="center"
            >
              {role}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }
)
