// import { createMuiTheme } from '@material-ui/core/styles';
// import { indigo } from '@material-ui/core/colors';

// // Configure Material UI theme
// export const theme = createMuiTheme({
//   palette: {
//     primary: indigo,
//     // accent: orange,
//     type: 'light',
//     background: {
//       default: '#FFF',
//     }
//   }
// });

// export default theme;


module.exports = {
    palette: {
      background: {
        default: '#FFF',
      },
      primary: '#3C8AEF',
      secondary: '#30c'
    },
    breakpoints: ['40em', '52em', '64em'],
    colors: {
      text: '#000',
      background: '#fff',
      primary: '#3C8AEF',
      secondary: '#30c',
      muted: '#f6f6f9',
      gray: '#dddddf',
    },
    fonts: {
      body: 'Open Sans, sans-serif, system-ui',
      heading: 'inherit',
      monospace: 'Menlo, monospace',
    },
    fontSizes: [
      12, 14, 16, 20, 24, 32, 48, 64, 96
    ],
    fontWeights: {
      body: 400,
      heading: 700,
      bold: 700,
    },
    lineHeights: {
      body: 1.5,
      heading: 1.25,
    },
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    sizes: {
      avatar: 48,
    },
    radii: {
      default: 4,
      circle: 99999,
    },
    shadows: {
      card: '0 0 4px rgba(0, 0, 0, .125)',
    },
    // rebass variants
    text: {
      heading: {
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
      },
      display: {
        fontFamily: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
        fontSize: [ 5, 6, 7 ],
      },
      caps: {
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
      },
    },
    variants: {
      avatar: {
        width: 'avatar',
        height: 'avatar',
        borderRadius: 'circle',
      },
      card: {
        p: 2,
        bg: 'background',
        boxShadow: 'card',
      },
      link: {
        color: 'primary',
      },
      nav: {
        fontSize: 1,
        fontWeight: 'bold',
        display: 'inline-block',
        p: 2,
        color: 'inherit',
        textDecoration: 'none',
        ':hover,:focus,.active': {
          color: 'primary',
        }
      },
    },
    buttons: {
      primary: {
        fontSize: 2,
        fontWeight: 'bold',
        color: 'background',
        bg: 'primary',
        borderRadius: 'default',
      },
      outline: {
        variant: 'buttons.primary',
        color: 'primary',
        bg: 'transparent',
        boxShadow: 'inset 0 0 2px',
      },
      secondary: {
        variant: 'buttons.primary',
        color: 'background',
        bg: 'secondary',
      },
    },
    styles: {
      root: {
        fontFamily: 'body',
        fontWeight: 'body',
        lineHeight: 'body',
      },
    },
    appBar: {
      root: {
        // backgroundColor: '#000'
      }
    }
  }
