import * as React from 'react';
import * as Rebass from 'rebass';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill"
import { path } from 'ramda';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { SubHeader } from '../../components/sub-header/sub-header';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import { Theme, Grid } from '@material-ui/core';

const ArrowDown = require('../../assets/icons/arrow-down.svg');
const Illo = require('../../assets/illustrations/letter-illustration.svg');

const theme = require('../../common/theme');

// const styles = (theme: Theme) => createStyles({

// })

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      flexDirection: 'column-reverse',
    } 
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

const ArrowContainer = styled(Rebass.Flex)`
  background-color: #fff;
  height: 100px;
  width: 100px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 34px rgba(7,29,59,0.1);
  /* shadow attributes */
`;

const ArrowWrapper = styled(Rebass.Flex)`
  margin-top: -50px;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-items: flex-start;
  display: none;
  @media screen and (min-width: ${theme.breakpoints[0]}) {
    display: flex;
  }
`;

// const Wrapper = withStyles((theme: Theme) => ({
//   root: {
//     display: 'flex',
//     [theme.breakpoints.down('md')]:{
//       flexDirection: 'row-reverse'
//     }
//   }
// }))(Box)

// const Wrapper = withStyles((theme: Theme) => ({
//   root: {
//     marginTop: theme.spacing(5),
//     paddingLeft: theme.spacing(3),
//     paddingRight: theme.spacing(3),
//     flexWrap: 'wrap',
//     alignItems: 'flex-start'
//   }
// }))(Box)


// const Header = styled(Rebass.Text).attrs({
//   fontSize: [5, '38px'],
// })`
//   /* font-size: 38px; */
//   line-height: 48px;
//   font-family: 'Avenir';
//   font-weight: 300;
//   margin-top: 20px;
// `

const Header = styled(Rebass.Text)`
  /* font-size: 38px; */
  line-height: 48px;
  font-family: 'Avenir';
  font-weight: 300;
  margin-top: 20px;
`

Header.defaultProps = {
  // fontSize: [5, '38px'], 
  fontSize: [5]
}

// const LetterText = styled(Rebass.Text).attrs({
//   fontFamily: 'Avenir',
//   fontSize: [2],
//   fontWeight: 300,
//   lineHeight: [1.7],
// })`
//   padding-top: 20px;
//   opacity: 0.5;
// `

const LetterText = styled(Rebass.Text)`
  padding-top: 20px;
  opacity: 0.5;
`

LetterText.defaultProps = {
  fontFamily: 'Open Sans',
  fontSize: [2],
  fontWeight: 300,
  lineHeight: [1.7], 
}

// const InnerContainer = styled(Rebass.Flex).attrs({
//   mt: [3, 5],
//   mx: [0, 0],
//   px: [3, 0],
//   flexWrap: 'wrap',
//   alignItems: 'flex-start'
// })``;

const NameText = styled(Rebass.Text)`
  color: #071D3B; 
  font-family: 'Open Sans';
`

const SignatureContainer = styled(Rebass.Flex)`
  flex-direction: row;
  margin-top: 50px;
  align-items: center;
  @media screen and (max-width: ${theme.breakpoints[0]}) { 
    justify-content: center;
  }
`;

const SignatureImageContainer = styled(Rebass.Flex)`
  flex-direction: column;
  flex-basis: 100%;
  min-width: 100px;
  max-width: 144px;
`

// const Seperator = styled(Rebass.Box).attrs({
//   mx: [3, 3, 4]
// })`
//   height: 50px;
//   width: 1px; 
//   background-color: #979797;
//   opacity: 0.33; 
//   /* margin-left: 25px;
//   margin-right: 25px; */
// `

const Seperator = styled(Rebass.Box)`
  height: 50px;
  width: 1px; 
  background-color: #979797;
  opacity: 0.33; 
`

Seperator.defaultProps = {
  mx: [3, 3, 4] 
}
// const IllustrationContainer = styled(Rebass.Flex).attrs({
//   flexDirection: 'column',
//   // alignItems: 'flex-start',
//   // justifyContent: 'center',
//   // width: [1/2, 5/12],
//   width: [1, 1/3],
//   mt: [3, 5],
//   mr: [4, 3, 3],
//   ml: [4, 0, 0],
// })``;

// const IllustrationContainer = ({ ...props }) => 
//   <Rebass.Flex flexDirection='column' width={[1, 1/3]} mt={[3, 5]} mr={[4,3,3]} ml={[4,0,0]} {...props} />

// const HR = styled(Rebass.Flex).attrs({
//   flexDirection: 'row',
//   width: '100%',
//   justifyContent: 'center',
//   alignItems: 'center'
// })`
//   height: 232px;
// `;

// const WelcomeText = `Welcome to Ari Dental Office, where beautiful smiles are created. With our state of the art technology, we will help to you to flash a beautiful smile with beaming confidence.

// At Ari Dental our goal is to give you confidence in your smile.  You deserve the best, and our team of professionals is here to provide you with top quality work.  We have modern, state of the art technology, set in two convenient locations with the patient comfort in mind. Here we provide an extensive list of dental services, including Invisalign.

// Whether it is a cleaning, a complete exam, restorative work, cosmetic dental services or anything in between, we cater to the whole family
// Our amicable and professional staff look forward to working with you.  Welcome to our practice, where beautiful smiles are made.`;


export const WelcomeSection = () => {
  const classes = useStyles({});
  const data = useStaticQuery(
    graphql`
      query {
        signature: file(relativePath: { eq: "aram-signature.png" }) {
          childImageSharp {
            fluid(maxWidth: 144, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        illustration: file(
          relativePath: {
            eq: "illustrations/letter-illustration.svg"
          },
          sourceInstanceName: {
            eq: "assets"
          }
        ) {
          publicURL
        }
        allContentfulLayoutCopy(
          filter: { node_locale: { eq: "en" }, title: { eq: "Landing > About Us" } }
          limit: 1
        ) {
          edges {
            node {
              headline
              subTitle
              copy {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
            }
          }
        }    
      }
    `
  );

  const headline = path(
    ['allContentfulLayoutCopy', 'edges', 0, 'node', 'headline'],
    data
  )
  const subTitle = path(
    ['allContentfulLayoutCopy', 'edges', 0, 'node', 'subTitle'],
    data
  )
  const body = path(
    [
      'allContentfulLayoutCopy',
      'edges',
      0,
      'node',
      'copy',
      'childMarkdownRemark',
      'rawMarkdownBody'
    ],
    data
  )

  return (
      <Container>

        <ArrowWrapper>
          <ArrowContainer>
            <ArrowDown />
          </ArrowContainer>
        </ArrowWrapper>

        <Grid container={true} spacing={3} className={classes.wrapper}>
          <Grid item={true} xs={12} md={4} className={classes.image}>
            <Box my={3}>
              <Rebass.Image src={data.illustration.publicURL} />
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={8}>
            <SubHeader title={subTitle} />
            <Header>{headline}</Header>
            <LetterText>{body}</LetterText>
            <SignatureContainer>
              <SignatureImageContainer>
                <Img fluid={data.signature.childImageSharp.fluid} />
              </SignatureImageContainer>
              <Seperator />
              <NameText>Dr. Aram Mohajeri</NameText>
            </SignatureContainer>
          </Grid>
        </Grid>
      </Container>
  )
}


/*
{/* 
          <View style={[{ flexDirection: 'row', paddingBottom: 75, flexWrap: 'wrap', justifyContent: 'flex-start' }, status.mobile && { maxWidth: windowWidth, alignItems: 'center'  }]}>
            
            <View style={[{ justifyContent: 'flex-start' }, status.mobile && { flex: 1, flexBasis: '100%', justifyContent: 'center', maxWidth: '100%',  alignItems: 'center'}]}>
              <Illo />
            </View>

            <View style={[{ flex: 0.6, flexBasis: 556, paddingLeft: 50 }, status.mobile && { flex: 1, flexBasis: windowWidth, marginTop: 50, marginHorizontal: 'auto' }]}>
              <SubHeader title="About Us" />
              <Text style={styles.welcomeHeaderText}>Welcome to Ari Dental Office</Text>
              <Text style={styles.welcomeText}>{WelcomeText}</Text>
              <View style={{ flexDirection: 'row', marginVertical: 25 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <View style={{ height: 50, width: 1, backgroundColor: '#979797', opacity: 0.33, marginHorizontal: 25 }} />
                  <Text style={{ color: '#071D3B', fontFamily: 'Avenir' }}>Dr. Aram Mohajeri</Text>
                </View>
              </View>
            </View>     
          </View>  
*/

// const styles = StyleSheet.create({
//   welcomeHeaderText: {
//     // ...webWeights.light,
//     fontSize: 38,
//     lineHeight: 48,
//     fontFamily: 'Avenir'
//   },
//   welcomeText: {
//     // ...webWeights.light,
//     fontSize: 16,
//     paddingTop: 20,
//     opacity: 0.5,
//     lineHeight: 27,
//     fontFamily: 'Avenir'
//   },
//   image: {
//     height: 368,
//     width: 701
//   },
//   signature: {
//     minWidth: 200,
//     maxWidth: 200
//   },
  // wrapper: {
  //   flex: 1,
  //   alignItems: 'center',
  //   width: '100%'
  // },
  // container: {
  //   maxWidth: 1000
  // },
  // arrowContainer: {
  //   backgroundColor: '#fff', 
  //   height: 100, 
  //   width: 100, 
  //   top: -50, 
  //   shadowOpacity: 0.5, 
  //   shadowColor: '#ccc', 
  //   shadowRadius: 20, 
  //   shadowOffset: { 
  //     width: 0, 
  //     height: 3 
  //   }, 
  //   borderRadius: 5, 
  //   justifyContent: 'center', 
  //   alignItems: 'center'
  // }
// });