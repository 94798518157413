import * as React from 'react';
import * as Rebass from 'rebass';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import { withStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import * as Color from 'color';
import { Link as GatsbyLink } from 'gatsby';

const Wrapper = styled(Rebass.Flex)`
  min-height: 100px;
  background-color: #F2F7FE;
`

Wrapper.defaultProps = {
  py: [5, 4],
  flexDirection: 'row',
  justifyContent: 'center'
}

const Container = styled(Rebass.Flex)`
  max-width: 1000px;
  flex-basis: 100%;
  justify-content: space-between;
`

Container.defaultProps = {
  flexWrap: 'wrap'
}

const DarkenColor = Color('rgb(60, 137, 239)').darken(0.1).hsl().toString();

const BlueButton = withStyles((theme: Theme) => ({
  root: {
    // color: theme.palette.getContrastText('rgb(60, 137, 239)'),
    color: '#3c8aef !important',
    // boxShadow: 'none',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'rgba(237,246,255, 0.5)',
    borderColor: 'rgba(60, 137, 239)',
    // boxShadow: '0px 3px 20px rgba(60, 137, 239, 0.5)',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    // fontFamily: [
    //   'Nunito',
    //   // 'Avenir'
    // ].join(','),
    // fontWeight: 600,
    '&:hover': {
      backgroundColor: 'rgba(72,177,251, 0.2)',
      borderColor: DarkenColor
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(237,246,255)',
      borderColor: DarkenColor
    },
    '&:focus': {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  },
  text: {
    color: '#3c8aef'
  }
}))(Button)

// const DownloadFormsButton = styled(Rebass.Button)`
//   background-color: #3c8aef;
//   border-width: 1px;
//   padding: 10px;
//   align-items: center;
//   justify-content: center;
//   border-radius: 5px;
//   box-shadow: 0px 3px 20px rgba(60, 137, 239, 0.5);
//   flex-basis: auto;
//   width: 100%;
//   height: 65px;
//   padding-left: 40px;
//   padding-right: 40px;
//   cursor: pointer;
//   font-family: 'Open Sans';
//   font-weight: bolder;
//   color: #FFFFFF;
//   letter-spacing: 0;
//   text-align: center;
// `

// DownloadFormsButton.defaultProps = {
//   fontSize: 2
// }

const PrimaryText = styled(Rebass.Text)`
  font-family: Avenir;
  font-weight: 300;
  color: #071D3B;
  letter-spacing: 0;
`

PrimaryText.defaultProps = {
  fontSize: 5
}

const SecondaryText = styled(Rebass.Text)`
  opacity: 0.5;
  font-family: 'Open Sans';
  font-weight: 300;
  /* font-size: 16px; */
  color: #071D3B;
  letter-spacing: 0;
  line-height: 1.5;
  /* line-height: 27px; */
`

SecondaryText.defaultProps = {
  fontSize: [3],
  mt: 2
}

const IconWrapper = styled(Rebass.Box)`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 4px 15px 0 rgba(7,29,59,0.06);
`

IconWrapper.defaultProps = {
  mr: [4],
  mb: [4, 0]
}

const LeftWrapper = styled(Rebass.Flex)``

LeftWrapper.defaultProps = {
  flexDirection: 'row',
  width: [1, 3 / 4],
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: ['center', 'flex-start']
}

const RightWrapper = styled(Rebass.Flex)``

RightWrapper.defaultProps = {
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: [1, 1 / 4],
  mt: [3, 0],
  mx: [3, 0]
}


export const CTADownloadForms = () => {
  const data = useStaticQuery(graphql`
    query {
      downloadFormsIcon: file(
        relativePath: {
          eq: "icons/download-forms.svg"
        },
        sourceInstanceName: {
          eq: "assets"
        }
      ) {
        publicURL
      }
    }
  `);

  return (
    <Wrapper>
      <Container>
        <LeftWrapper>
          <IconWrapper>
            <Rebass.Image src={data.downloadFormsIcon.publicURL} />
          </IconWrapper>
          <Rebass.Box my={[3, 'auto']} mx={[3, 0]} width={[1, 'auto']}>
            <PrimaryText>Download Forms</PrimaryText>
            <SecondaryText>Looking to save time? Fill out our forms before your visit.</SecondaryText>
          </Rebass.Box>
        </LeftWrapper>
        <RightWrapper>
          <GatsbyLink
            to='/en/resources/'
            style={{ textDecoration: 'none' }}
          >
            <BlueButton>
              Download Forms
            </BlueButton>
          </GatsbyLink>
        </RightWrapper>
      </Container>
    </Wrapper>
  )
} 
