import * as React from 'react';
import * as Rebass from 'rebass';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { BigPlayButton, Player } from 'video-react'
import 'video-react/dist/video-react.css'

import { Wrapper } from '../../components/wrapper';

const Container = styled(Rebass.Flex).attrs({
  width: 1,
})`
  max-width: 1000px;
`
const VideoWrapper = styled(Wrapper).attrs({
  backgroundColor: '#000'
})``

export const IntroVideo = () => {
  return (
    <VideoWrapper>
      <Container>
        <Player
          playsInline
          src={`https://s3.amazonaws.com/static.ari.dental/Invisalign_Life_Uninterrupted_English_In_Office_60_2_9_2020_3_15_38_PM.mp4`}
        >
          <BigPlayButton position="center" />
        </Player>
      </Container>
    </VideoWrapper>
  )
}