import React from 'react'
import Loadable from 'react-loadable'
import { Helmet as Head } from 'react-helmet'
import * as Scroll from 'react-scroll'

import SEO from '../components/seo'
import { Layout } from '../components/layout'

import styled from '@emotion/styled'

const HR = styled.div`
  background-image: linear-gradient(to right, #333 5%, rgba(7, 29, 59, 0) 5%);
  background-position: top;
  background-size: 6.5px 1px;
  background-repeat: repeat-x;
  height: 1px;
`

import { Notifications } from '../sections/notifications'
import { HeroSection } from '../sections/hero'
// import { Instagram } from '../sections/instagram'
import { IntroVideo } from '../sections/intro-video/intro-video'
import { ServicesSection } from '../sections/services'
import { NewsSection } from '../sections/news'
import { TeamSection } from '../sections/team'
import { WelcomeSection } from '../sections/welcome'
import { CTAChatWithUs } from '../sections/cta-chat-with-us/cta-chat-with-us'
import { CTAMakeAnAppointment } from '../sections/cta-make-an-appointment/cta-make-an-appointment'
import { CTADownloadForms } from '../sections/cta-download-forms/cta-download-forms'

const ContactSection = Loadable({
  loader: () => import('../sections/contact'),
  loading: () => null
})

const Smooch = (global as any).Smooch

const sendMessage = ({ message }) => {
  if (typeof Smooch !== 'undefined' && (global as any).smoochLoaded === true) {
    if (typeof Smooch.isOpened === 'function' && !Smooch.isOpened()) {
      Smooch.open()
    }
    if (typeof Smooch.sendMessage === 'function') {
      Smooch.sendMessage(message)
    } else {
      console.log('sendMessage not function', Smooch)
    }
  }
}

export default ({ pageContext }): React.ReactNode => {
  return React.useMemo(
    () => (
      <Layout langKey={pageContext.langKey}>
        <SEO title="Experienced Dental Professionals &amp; Exemplary Care" />

        <Head>
          <link rel="canonical" href="//aridental.ca" />
          <link rel="canonical" href="//kingwest.dental" />
          <link rel="canonical" href="//dramohajer.com" />
        </Head>

        <Notifications />

        <HeroSection />

        <WelcomeSection />

        <ServicesSection />

        <IntroVideo />

        <TeamSection />

        <CTAMakeAnAppointment />

        <HR />

        <NewsSection />

        <CTADownloadForms />

        {/* <Instagram /> */}

        <CTAChatWithUs />

        <ContactSection />
      </Layout>
    ),
    []
  )
}
